import ContentLoader from "react-content-loader";

const PicksCarouselSkeleton = ({ ...props }) => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={42}
        backgroundColor="#f3f3f3"
        className="mt-2 flex-none rounded-lg"
        foregroundColor="#ecebeb"
        animate
        {...props}
      >
        <rect x="9" y="5" rx="0" ry="0" width="100%" height="42" />
      </ContentLoader>
      <div className="mb-1 mt-4 flex content-start gap-2 overflow-x-auto pr-1">
        {Array.from({ length: 6 }).map((_, index) => {
          return (
            <ContentLoader
              speed={2}
              width={284}
              height={202}
              key={index}
              viewBox="0 0 284 202"
              backgroundColor="#f3f3f3"
              className="flex-none rounded-lg"
              foregroundColor="#ecebeb"
              animate
              {...props}
            >
              <rect x="9" y="5" rx="0" ry="0" width="284" height="202" />
            </ContentLoader>
          );
        })}
      </div>
    </div>
  );
};

export default PicksCarouselSkeleton;
