import labels from "@/config/labels";

import Icon from "./icon/Icon";

export const LiveBox = ({ className }) => (
  <div
    className={`flex items-center gap-2 text-xs uppercase text-red ${className}`}
  >
    {labels.liveStatus}
    <Icon name={"liveIcon"} height={12} width={12} />
  </div>
);
