import { useRouter } from "next/router";

import labels from "@/config/labels";
import { CONSENSUS_PICKS_MENU } from "@/config/menus";
import { useAppContext } from "@/hooks/useAppContext";
import { useGamesContext } from "@/hooks/useGamesContext";

import Icon from "../icon/Icon";
import PicksCarouselSkeleton from "../skeleton/PicksCarouselSkeleton";
import GameCardCarousel from "./components/GameCardCarousel";

const PicksCarousel = ({ onSelectGame }) => {
  const { selectedLeague, setSelectedLeague } = useAppContext();
  const {
    games,
    isGamesLoading,
    soccerGames,
    isSoccerGamesLoading,
    collegeGames,
    isCollegeGamesLoading,
    wnbaGames,
    isWnbaGamesLoading,
  } = useGamesContext();
  const router = useRouter();
  const isCollegeLeague =
    selectedLeague === "ncaaf" || selectedLeague === "ncaab";
  const isSoccerLeague = selectedLeague === "soccer";
  const isWnbaLeague = selectedLeague === "wnba";
  const isLoading = isWnbaLeague
    ? isWnbaGamesLoading
    : isCollegeLeague
      ? isCollegeGamesLoading
      : isSoccerLeague
        ? isSoccerGamesLoading
        : isGamesLoading;

  const getSelectedGames = (
    games,
    selectedLeague,
    soccerGames,
    collegeGames
  ) => {
    switch (selectedLeague) {
      case "soccer":
        return soccerGames;
      case "wnba":
        return wnbaGames;
      case "ncaaf":
      case "ncaab":
        return collegeGames?.[selectedLeague];
      default:
        return games?.[selectedLeague];
    }
  };

  const selectedGames = getSelectedGames(
    games,
    selectedLeague,
    soccerGames,
    collegeGames
  );

  function onSelect(option) {
    setSelectedLeague(option);
  }

  const noGames = Object.keys(selectedGames ?? {}).length === 0;

  const isHomepage = router.pathname === "/";

  return (
    <div
      className={`picks-carousel border border-site-bg bg-site-bg ${noGames && !isLoading ? "hidden" : "block"} ${isHomepage ? "px-4 pt-4" : ""}`}
    >
      <h2
        className={`mb-2 font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem] ${isHomepage ? "block" : "hidden"}`}
      >
        {labels.homepagePicksTitle}
      </h2>
      {isLoading && <PicksCarouselSkeleton />}
      {!isLoading && (
        <>
          <div
            className={`no-scrollbar flex overflow-x-scroll rounded-lg border border-card-border bg-white ${isHomepage ? "block" : "hidden"}`}
          >
            {Object.values(CONSENSUS_PICKS_MENU).map((option) => {
              const gamesByOption = getSelectedGames(
                games,
                option.id,
                soccerGames,
                collegeGames
              );
              const hasGames = Object.keys(gamesByOption ?? {}).length > 0;

              if (hasGames) {
                const optionClassName = `flex flex-row items-center py-2 px-3 focus:outline-0 hover:outline-0 rounded-lg whitespace-nowrap items-center ${option.id === selectedLeague ? "bg-select-blue text-white focus:bg-select-blue hover:bg-select-blue" : "bg-white text-nav-blue focus:bg-select-hover hover:bg-select-hover"}`;

                return (
                  <button
                    key={option?.id}
                    aria-label={option?.name}
                    className={optionClassName}
                    onClick={() => onSelect(option?.id)}
                  >
                    <Icon
                      className={`${option?.id === "all" ? "mr-0 lg:mr-2" : "mr-2"}`}
                      height={"16px"}
                      name={`${option?.id}Select`}
                      width={"20px"}
                      color="currentColor"
                    />
                    <p
                      className={`${option?.id === "all" ? "hidden lg:block" : ""}`}
                    >
                      {option?.name}
                    </p>
                  </button>
                );
              }
            })}
          </div>
          <div className="mb-1 mt-4 flex content-start gap-2 overflow-x-auto pr-1">
            {selectedGames &&
              Object.values(selectedGames).map((game) => {
                return (
                  <GameCardCarousel
                    key={game?.gameKey}
                    game={game}
                    onSelectGame={onSelectGame}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default PicksCarousel;
