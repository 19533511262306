import dayjs from "dayjs";

import labels from "@/config/labels";
import { getDateDifference, getGameStartDateAndTime } from "@/utilities/dates";

import Icon from "./icon/Icon";

export const GameStartTimeDisplay = ({
  startTime,
  isRow = true,
  showLegend = true,
  league,
  targetLeague,
}) => {
  const { date, time } = getGameStartDateAndTime(startTime);
  let { hoursDiff, minutesDiff, daysDiff } = getDateDifference(startTime);
  const formattedLeague = league ? `${league} - ` : "";

  const shouldRenderNextGame =
    hoursDiff < 1 && minutesDiff > 0 && daysDiff === 0;
  const shouldRenderTodayGame =
    !shouldRenderNextGame && daysDiff === 0 && hoursDiff > 0;
  const shouldRenderTomorrowGame =
    !shouldRenderNextGame &&
    !shouldRenderTodayGame &&
    daysDiff === 1 &&
    (hoursDiff > 0 || minutesDiff > 0);

  const shouldRenderLegend =
    showLegend &&
    (shouldRenderNextGame || shouldRenderTodayGame || shouldRenderTomorrowGame);

  const renderDefaultFormat = () => {
    const formattedTime = isRow ? `, ${time}` : time;
    return (
      <p
        className={`flex w-fit ${
          isRow ? "flex-row items-start" : "flex-col items-center px-6"
        } text-xs font-medium text-darker-grey`}
      >
        <span className="font-bold uppercase text-dark">{`${formattedLeague}${date}`}</span>
        <span className="text-card-dark">{formattedTime} ET</span>
      </p>
    );
  };

  const renderLegendFormat = () => {
    const formattedDateFromNow = dayjs(startTime)
      .fromNow()
      ?.replace("in", "")
      ?.replace("minute", "min");
    const label = shouldRenderNextGame
      ? `${labels.gameStartTimeDisplayNextGame} ${formattedDateFromNow}`
      : shouldRenderTodayGame
        ? `${labels.gameStartTimeDisplayTodayGame} ${dayjs(startTime).format(
            "h:mm A"
          )} ET`
        : shouldRenderTomorrowGame
          ? `${labels.gameStartTimeDisplayTomorrowGame}  ${dayjs(
              startTime
            ).format("h:mm A")} ET`
          : "";
    return (
      <div className="flex w-fit items-center gap-1 rounded-lg bg-blue px-[0.35rem] py-1 text-xs text-white opacity-60 md:px-2">
        {formattedLeague && <p>{formattedLeague}</p>}
        <Icon name={"clock"} color="white" width={12} height={12} />
        <p className="text-center md:text-left">{label}</p>
      </div>
    );
  };

  return (
    <>{shouldRenderLegend ? renderLegendFormat() : renderDefaultFormat()}</>
  );
};
